<template>
  <div>
    <nav-bar />
    <contest-hero />
    <div class="container mt-5">
      <div class="row justify-content-center" v-if="success">
        <div class="col-md-8 my-5">
          <div class="alert alert-success py-5">
            <p>Thank you for submitting your Vision with us.</p>
            <p>
              Our team will review and if it meets our standard, it will be
              approved
            </p>
            <router-link class="btn btn-danger" to="/contest"
              >Go to challenges</router-link
            >
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!success">
        <div class="col-md-8 my-2">
          <h2 class="text-capitalize">Add your Vision</h2>
          <router-link to="/contest" class="btn"> Back </router-link>
        </div>
        <div class="col-md-8 py-5">
          <form
            action=""
            @submit.prevent="addLeadersVision"
            enctype="multipart/form-data"
          >
            <div class="form-group">
              <label for="name"
                >Full Name
                <strong class="text-danger">(required)</strong></label
              >
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="name"
                required
              />
            </div>
            <div class="form-group">
              <label for="DOB"
                >Age <strong class="text-danger">(required)</strong></label
              >
              <input
                type="number"
                required
                name="DOB"
                class="form-control"
                v-model="age"
              />
            </div>
            <p class="p-0">
              Gender <strong class="text-danger">(required)</strong>
            </p>
            <div class="form-check ml-2">
              <input
                v-model="gender"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="Male"
                checked
                required
              />
              <label class="form-check-label" for="exampleRadios1">
                Male
              </label>
            </div>
            <div class="form-check ml-2">
              <input
                class="form-check-input"
                v-model="gender"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="Female"
              />
              <label class="form-check-label" for="exampleRadios2">
                Female
              </label>
            </div>
            <div class="form-group">
              <label for="certification_number"
                >Market <strong class="text-danger">(required)</strong></label
              >
              <multiselect
                id="sales-markets"
                v-model="market_id"
                deselect-label="Can't remove this value"
                track-by="id"
                label="name"
                placeholder="Select Maket"
                :options="markets"
              >
              </multiselect>
            </div>
            <div class="form-group">
              <label for="issue"
                >Issue Area
                <strong class="text-danger">(required)</strong></label
              >
              <multiselect
                id="sales-markets"
                v-model="issueArea"
                deselect-label="Can't remove this value"
                placeholder="Select Issue Area"
                :options="issueAreaList"
              >
              </multiselect>
            </div>
            <div class="form-group">
              <label for="title"
                >Heading <strong class="text-danger">(required)</strong></label
              >
              <input
                required
                type="text"
                name="heading"
                class="form-control"
                v-model="heading"
              />
            </div>
            <div class="form-group">
              <label for="statement"
                >Summary <strong class="text-danger">(required)</strong></label
              >
              <textarea
                required
                name="statement"
                class="form-control"
                rows="15"
                v-model="summary"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="basic-url"
                >Upload Your Photo
                <strong class="text-danger">(optional)</strong></label
              >
              <div class="custom-file">
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadImage"
                />
                <label class="custom-file-label" for="validatedCustomFile">
                  {{ picTitle ? picTitle : "Choose file..." }}
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="basic-url"
                >Attach PDF
                <strong class="text-danger">(optional)</strong></label
              >
              <div class="custom-file">
                <input
                  accept="application/pdf"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadPdf"
                />
                <label class="custom-file-label" for="validatedCustomFile">{{
                  pdfTitle ? pdfTitle : "Choose file..."
                }}</label>
              </div>
            </div>
            <div class="form-group">
              <label for="basic-url"
                >Attach Document
                <strong class="text-danger">(optional)</strong></label
              >
              <div class="custom-file">
                <input
                  accept=".doc,.docx,"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadDoc"
                />
                <label class="custom-file-label" for="validatedCustomFile">
                  {{ docTitle ? docTitle : "Choose file..." }}
                </label>
                <small>{{ docTitle }}</small>
              </div>
            </div>
            <label for="basic-url"
              >Add Support Video
              <strong class="text-danger">(optional)</strong></label
            >
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3">URL</span>
              </div>
              <input
                v-model="video"
                type="text"
                class="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
            <div class="alert alert-danger" v-if="issueAreaError">
              <strong class="">{{ issueAreaError }}</strong>
            </div>
            <div class="alert alert-danger" v-if="marketError">
              <strong class="">{{ marketError }}</strong>
            </div>
            <div class="alert alert-danger" v-if="pdfError">
              <strong class="">{{ pdfError }}</strong>
            </div>
            <div class="alert alert-danger" v-if="docError">
              <strong class="">{{ docError }}</strong>
            </div>
            <div class="alert alert-danger" v-if="picError">
              <strong class="">{{ picError }}</strong>
            </div>
            <div v-if="errors" class="text alert-danger">
              <p v-for="error in errors" :key="error">
                {{ error }}
              </p>
            </div>
            <button
              class="btn btn-lg btn-warning float-right"
              style="font-size: 0.9em"
              type="submit"
            >
              <span
                v-if="isLoading"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import url from "../../helpers/url";
import axios from "axios";
import toasterMixin from "../../mixins/toasterMinxin";
import NavBar from "../New/NavBar.vue";
import ContestHero from "../New/Contest/ContestHero.vue";
import Footer from "../New/Footer.vue";
export default {
  components: {
    Multiselect,
    NavBar,
    ContestHero,
    Footer, // Footer,
    // Partners,
  },
  data() {
    return {
      isLoading: false,
      markets: [],
      issueAreaList: [
        "Agriculture & Food Security",
        "Industrialisation & Infrastructure & Energy Systems",
        "Good Governance, Inclusion & Equity",
        "Technology",
        "Education & Skills",
        "Health & Well-being",
        "Natural Capital & Environment",
        "Trade & Continental Economic Integration",
      ],
      gender: "",
      issueArea: "",
      name: "",
      pic: "",
      age: "",
      heading: "",
      summary: "",
      market_id: "",
      pdf: "",
      video: "",
      doc: "",
      picTitle: "",
      pdfTitle: "",
      docTitle: "",
      success: false,
      marketError: null,
      issueAreaError: null,

      pdfError: null,
      picError: null,
      docError: null,
      errors: [],
    };
  },
  methods: {
    uploadImage(event) {
      this.pic = event.target.files;
      this.picTitle = this.pic[0].name;
      if (this.pic) {
        let size;
        for (let i = 0; i < this.pic.length; i++) {
          size = Math.round(this.pic[i].size / 1024);
        }
        if (size > 2048) {
          this.toast({
            message: `Your picture shouldn't be more than 2MB`,
            type: "error",
          });
          return;
        }
      }
    },
    uploadDoc(event) {
      this.doc = event.target.files;
      this.docTitle = this.doc[0].name;
      if (this.doc) {
        let size;
        for (let i = 0; i < this.doc.length; i++) {
          size = Math.round(this.doc[i].size / 1024);
        }
        if (size > 2048) {
          this.toast({
            message: `Document shouldn't be more than 2MB`,
            type: "error",
          });
          return;
        }
      }
    },
    uploadPdf(event) {
      this.pdf = event.target.files;
      this.pdfTitle = this.pdf[0].name;
      if (this.pdf) {
        let size;
        for (let i = 0; i < this.pdf.length; i++) {
          size = Math.round(this.pdf[i].size / 1024);
        }
        if (size > 2048) {
          this.toast({
            message: `PDF shouldn't be more than 2MB`,
            type: "error",
          });
          return;
        }
      }
    },
    addLeadersVision() {
      //validation
      this.isLoading = true;

      if (this.market_id === "") {
        this.marketError = "Please market is required.";
        this.isLoading = false;
        return;
      } else {
        this.marketError = "";
      }
      if (this.issueArea === "") {
        this.issueAreaError = "Please issue area is required.";
        this.isLoading = false;
        return;
      } else {
        this.issueAreaError = "";
      }

      const formData = new FormData();

      formData.append("name", this.name);
      formData.append("age", this.age);
      formData.append("heading", this.heading);
      formData.append("summary", this.summary);
      formData.append("gender", this.gender);
      formData.append("issue_area", this.issueArea);
      formData.append("market_id", this.market_id.id);
      formData.append("video", this.video);

      if (this.pic) {
        for (let i = 0; i < this.pic.length; i++) {
          formData.append("image  ", this.pic[i]);
        }
      } else {
        formData.append("image", "");
      }
      if (this.pdf) {
        for (let i = 0; i < this.pdf.length; i++) {
          formData.append("pdf", this.pdf[i]);
        }
      } else {
        formData.append("pdf", "");
      }
      if (this.doc) {
        for (let i = 0; i < this.doc.length; i++) {
          formData.append("doc", this.doc[i]);
        }
      } else {
        formData.append("doc", "");
      }

      this.$store
        .dispatch("addLeadersVision", formData)
        .then((response) => {
          if (response) {
            this.isLoading = false;
            this.success = true;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response.status == 422) {
            const error = Object.keys(err.response.data);
            const errors = Object.values(err.response.data);
            if (error == "pdf") {
              this.pdfError = "PDF size should be less than 2MB";
            } else if (error == "doc") {
              this.pdfError = "doc size should be less than 2MB";
            } else if (error == "pic") {
              this.pdfError = "Photo size should be less than 2MB";
            } else {
              this.error = errors;
            }
          }
          if (err?.response?.data == undefined) {
            this.toast({
              message: "Network error, Check network connection and try again",
              type: "error",
            });
          }
        });
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
    axios.get(`${url()}/api/market/`).then((response) => {
      this.markets = response.data;
    });
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style  scoped>

h2 {
  display: inline;
}
a {
  float: right;
  border: 2px solid black;
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.8em;
    margin-top: 50px;
  }
}
</style>